import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';

import { take, map } from 'rxjs/operators';

import { Team, TeamGroup, Player, Event, Mission } from '../classes/index';

import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable()
export class TeamService {

	constructor(
		private http: HttpClient,
		private afs: AngularFirestore
	) { }

	listTeams(gameId){
		return this.afs.doc(`games/${gameId}`).collection('teams') //, ref => ref.orderBy('startTime','desc')
		//.valueChanges({ idField: 'id' })
		.get({ source: 'server' })
		.pipe(map(collection => {
			const data = [];
			collection.forEach(a => {
				let item = new Team();
				item.fromJSON(a.data());
				item.id = a.id;
				data.push(item);
			});
			return data;
		}));
	}

	getLastTeam(gameId){
		return this.afs.doc(`games/${gameId}`).collection('teams', ref => ref.orderBy('startTime','desc').limit(1))
		.valueChanges({ idField: 'id' })
		.pipe(map(collection => {
			return collection.map(data => {
				let object = new Team();
				object.fromJSON(data);
				return object;
			});
		}));
	}

	listLeaderboardTeams(gameId, groupId, limit){
		return this.afs.doc(`games/${gameId}`).collection('teams', ref => {
			let query:any = ref;
			if(groupId){
				query = query.where('groupId','==',groupId)
			}
			query = query.orderBy('score','desc');
			return (limit>0 ? query.limit(limit) : query);
		} ) //, ref => ref.orderBy('startTime','desc')
		.valueChanges({ idField: 'id' })
		.pipe(map(collection => {
			return collection.map(data => {
				let object = new Team();
				object.fromJSON(data);
				return object;
			});
		}));
	}

	listTeamPositions(gameId){
		return this.afs.doc(`games/${gameId}`).collection('teams_position') //, ref => ref.orderBy('startTime','desc')
		.valueChanges({ idField: 'id' });
		/*.pipe(map(collection => {
			return collection.map(data => {
				let object = new TeamPosition();
				object.fromJSON(data);
				return object;
			});
		}));*/
	}

	async listJournalTeam(newValue, gameId, teamId, game){
		let teamData:any = {...newValue};
        let players = [], objects, missions = [] 
		teamData.players = await this.listTeamPlayers(gameId, teamId).pipe(take(1)).toPromise()
		missions = await this.listTeamMissions(gameId, teamId).pipe(take(1)).toPromise()
		console.log()
        //teamData.missions = missions;
        teamData.riddleCount = missions.filter(function( mission ) {return mission.complete && mission.type == 'REWARD' && mission.rewardMode == 'RIDDLE'}).length;
        teamData.clueCount = 0;
        teamData.missionCount = 0;
        for(let mission of missions){
            if(mission.complete){
                teamData.missionCount++;
            }
            if(mission.riddleClues){
                for(let clue of mission.riddleClues){
                    if(clue.received){
                        teamData.clueCount++;
                    }
                }
            }
        }
		/**
			this.afs.doc(`games/${gameId}`).collection('teams').doc(teamId).collection('objects').get().pipe(take(1))
			.subscribe(async teamObjects => {
				if(teamObjects) teamData.objects = objects;
			});

			this.afs.doc(`games/${gameId}/teams/${teamId}`).collection('events').get().pipe(take(1)).subscribe(async events => {
				if(events) teamData.events = events;
			});
			let events = [];
			let picCount = 0;
			for(let event of events){
				if(event.type == 'PICTURE_PROOF_SENT' && event.thumbFileURL){
					teamData.teamPictureUrl = event.thumbFileURL;
					picCount++;
					if(!event.galleryCopyDate){
						let data = {
							gameId: gameId,
							gameName: game.data().name,
							teamId: teamId,
							eventId: event.id,
							teamName: teamData.name || 'unknown',
							fileURL: event.fileURL,
							thumbFileURL: event.thumbFileURL,
							fileName: event.fileName,
							time: event.time
						};
						await firebase.firestore().collection('games').doc(gameId).collection('teampictures').add(data);
						const eventRef = firebase.firestore().doc(`games/${gameId}/teams/${teamId}/events/${event.id}`);
						await eventRef.update({galleryCopyDate:new Date().getTime()});
					}
				}
			}
			teamData.freeGames = await this.gameService.listFreeeGame() 
		*/
		if(teamData.score) {
			console.log('teamData :',teamData)
			console.log('groupId :',teamData.groupId)
			let teamsQuerySnapshot
			if(teamData.groupId) teamsQuerySnapshot = await firebase.firestore().collection('games').doc(gameId).collection('teams')
			.where("score", ">", teamData.score).where("groupId", "==", teamData.groupId).get()
			else 
			teamsQuerySnapshot = await firebase.firestore().collection('games').doc(gameId).collection('teams')
			.where("score", ">", teamData.score).get()

			console.log('teamsQuerySnapshot docs :',teamsQuerySnapshot.docs)
			teamData.leaderboardPosition = teamsQuerySnapshot.docs.length+1;

			console.log('leaderboardPosition :',teamData.leaderboardPosition)

		}else teamData.leaderboardPosition = 0
        //LEADERBOARD
        const topTeamsQuerySnapshot = await firebase.firestore().collection('games').doc(gameId).collection('teams')
        .orderBy('score', 'desc').limit(10).get();
        let teamCount = 0;
        let teams = [];
        for (const teamSnapshot of topTeamsQuerySnapshot.docs) {
            if(teamSnapshot.id !== 'test_team' && teamSnapshot.data().initiated){
                teamCount++;
                teams.push({id:teamSnapshot.id, position:teamCount, team:teamSnapshot.data().name, points:teamSnapshot.data().score});
            }
        }
        teamData.leaderboard = teams;
        teamData.duration = newValue.duration
        teamData.startTime = newValue.startTime
        teamData.stopTime = newValue.stopTime
		return teamData
	}

	getBlogGame(gameId, lang){
		return this.http.get(`https://www.coddygames.com/${lang}/exports/api/posts/${gameId}/${environment.webAppKey}`);
	}

	getRelatedGames(gameId, lang){
		return this.http.get(`https://www.coddygames.com/${lang}/exports/api/games/${gameId}/${environment.webAppKey}`);
	}

	listTodayTeams(){
		// Get all the user's comments, no matter how deeply nested
		var start = moment().utc().startOf('day').valueOf(); 
    	var end = moment().utc().endOf('day').valueOf(); 
    	console.log('compute stats from '+start+' to '+end);
    	//Teams started per game
		return this.afs.collectionGroup('teams', ref => ref.where("startTime", ">=", start).where("startTime", "<=", end).orderBy('startTime','desc'))
		.snapshotChanges()
		.pipe(map(changes => {
			return changes.map(a => {
				//console.log('received mission:'+JSON.stringify(a.payload.doc.data()));
				let data:any = a.payload.doc.data();
				data.id = a.payload.doc.id;
				return data;
			});
		}));
	}

	listEmptyTeams(){
    	console.log('listEmptyTeams');
    	//Teams started per game
		return this.afs.collectionGroup('teams', ref => ref.where("startTime", ">=", 0).where("complete", "==", false).orderBy('startTime','asc'))
		.snapshotChanges()
		.pipe(map(changes => {
			return changes.map(a => {
				//console.log('received mission:'+JSON.stringify(a.payload.doc.data()));
				let data:any = a.payload.doc.data();
				data.id = a.payload.doc.id;
				return data;
			});
		}));
	}

	listTodayTeamsMobile(){
		// Get all the user's comments, no matter how deeply nested
		var start = moment().utc().startOf('day').valueOf(); 
    	var end = moment().utc().endOf('day').valueOf(); 
    	console.log('compute stats from '+start+' to '+end);
    	//Teams started per game
		return this.afs.collectionGroup('teams', ref => ref.where("startTime", ">=", start).where("startTime", "<=", end).orderBy('startTime','desc'))
		.get()
		.pipe(map(collection => {
			const data = [];
			collection.forEach(a => {
			  const item = a.data() as Team;
			  item.id = a.id;
			  data.push(item);
			});
			return data;
		}))
	}

	listAllTeamsByGame(dateTimeDebut, dateTimeFin, gameId){
		if(dateTimeDebut && dateTimeFin){
			var start = moment(dateTimeDebut).startOf('day').valueOf();
			var end = moment(dateTimeFin).endOf('day').valueOf();
			// Get all the user's
			return this.afs.collectionGroup('teams', ref => ref.where("startTime", ">=", start).where("startTime", "<=", end))
			.snapshotChanges()
			.pipe(map(changes => {
				return changes.map(a => {
					let object = new Team();
					object.fromJSON(a.payload.doc.data());
					object.id = a.payload.doc.id;
					return object;
				});
			}));
		}else{
			if(gameId.length > 0) return this.listAllTeams(gameId)
			else return
		}
	}

	listTeamsByCode(code){
		return this.afs.collectionGroup('teams', ref => ref.where("password", "==", code))
		.get()
		.pipe(map(collection => {
			const data = [];
			collection.forEach(a => {
			  const item = a.data() as Team;
			  item.id = a.id;
			  data.push(item);
			});
			return data;
		}))
	}

	listTeamsByGroupId(groupId){
		return this.afs.collectionGroup('teams', ref => ref.where("groupId", "==", groupId))
		.get()
		.pipe(map(collection => {
			const data = [];
			collection.forEach(a => {
				let object = new Team();
				object.fromJSON(a.data());
				object.id = a.id;
			  	data.push(object);
			});
			return data;
		}))
	}

	listTeamsByName(name){
		return this.afs.collectionGroup('teams', ref => ref.where("name", "==", name))
		.get()
		.pipe(map(collection => {
			const data = [];
			collection.forEach(a => {
			  const item = a.data() as Team;
			  item.id = a.id;
			  data.push(item);
			});
			return data;
		}))
	}

	listAllTeams(gameId){
		return this.afs.collectionGroup('teams').snapshotChanges().pipe(map(changes => {
			return changes.map(a => {
				let data:any = a.payload.doc.data();
				data.id = a.payload.doc.id;
				if(!gameId){
					return data
				} else {
					if(data.gameId === gameId){
						return data;
					}
				}
			});
		}));
	}

	listAllTeamsGlobalSearch(val){
		return this.afs.collectionGroup('teams', ref => ref.orderBy("name").startAfter(val).startAt(val).endAt(val+"\uf8ff"))
		.snapshotChanges()
		.pipe(map(changes => {
			return changes.map(a => {
				let data:any = a.payload.doc.data();
				data.id = a.payload.doc.id;
				return data
			});
		}))
	}

	listLinkedTeams(gameId, originalTeamId){
		console.log('listLinkedTeams:'+gameId+'/'+originalTeamId);
		return this.afs.doc(`games/${gameId}`).collection('teams', ref => ref.where('originalTeamId', '==', originalTeamId)) //, ref => ref.orderBy('startTime','desc')
		.get()
		.pipe(map(collection => {
			const data = [];
			collection.forEach(a => {
			  const item = a.data() as Team;
			  item.id = a.id;
			  data.push(item);
			});
			return data;
		}))
	}

	async fetchTeamJournal(gameId, teamId)
	{
		const team: any = await this.getTeam(gameId, teamId).pipe(take(1)).toPromise()

		const headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		headers.append('Accept', 'text/html');

		team.players = []
		team.events = []
		team.objects = []
		team.riddleCount = 0
		team.clueCount = 0
		team.missionCount = 0
		team.teamPictureUrl = null
		team.leaderboardPosition = 1
		team.leaderboard = []

		const data = {
			lang: team.lang,
            teamId: teamId,
            gameId: gameId,
            teamData: team
		}
		console.log('fetchTeamJournal:'+JSON.stringify(data));
		return this.http.post(
			'https://www.coddygames.com/journal-api', 
			data, 
			{
				headers: headers, 
				responseType:'text'
			});
	}

	genTeamJournal(data){
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		headers.append('Accept', 'text/html');
		console.log('fetchTeamJournal:'+JSON.stringify(data));
		return this.http.post(
			'https://www.coddygames.com/journal-api', 
			data, 
			{
				headers: headers, 
				responseType:'text'
			});
	}

	getTeam(gameId, teamUid){
		return this.afs.doc(`games/${gameId}`).collection('teams').doc(`${teamUid}`).valueChanges()
		.pipe(map(team => {
			if(team){
				let obj = new Team();
				obj.id = teamUid;
				obj.fromJSON(team);
				if(team){
					obj.id = teamUid;
				}
				return obj;
			}
			return null;
		}));
	}

	findTeam(email){
		return this.afs.collectionGroup('teams', ref => ref.where('email', '==', email))
		.get()
		.pipe(map(collection => {
			const data = [];
			collection.forEach(a => {
			  let item = new Team();
			  item.fromJSON(a.data());
			  item.id = a.id;
			  data.push(item);
			});
			return data;
		}))
	}
	findTeamByTeamCode(teamCode){
		return this.afs.collectionGroup('teams', ref => ref.where('password', '==', teamCode))
		.get()
		.pipe(map(collection => {
			const data = [];
			collection.forEach(a => {
			  const item = a.data() as Team;
			  item.id = a.id;
			  data.push(item);
			});
			return data;
		}))
	}

	getTeamByTicketId(ticketId){
		return this.afs.collectionGroup('teams', ref => ref.where("ticketId", "==", ticketId))
		.snapshotChanges()
		.pipe(map(changes => {
			return changes.map(a => {
				//console.log('received mission:'+JSON.stringify(a.payload.doc.data()));
				let data:any = a.payload.doc.data();
				data.id = a.payload.doc.id;
				return data;
			});
		}));
	}

	getTeamByBookingRef(bookingRef){
		//console.log('getTeamByBookingRef:'+bookingRef);
		return this.afs.collectionGroup('teams', ref => 
			ref.where("bookingRef", "==", bookingRef)
		)
		.snapshotChanges()
		.pipe(map(changes => {
			return changes.map(a => {
				//console.log('received mission:'+JSON.stringify(a.payload.doc.data()));
				let team = new Team();
				team.fromJSON(a.payload.doc.data());
				team.id = a.payload.doc.id;
				return team;
			});
		}));
	}

	getTeamByBookingRefLike(bookingRef){
		console.log('getTeamByBookingRef:'+bookingRef);
		console.log('getTeamByBookingRef-endAt:'+bookingRef+'\uf8ff');
		return this.afs.collectionGroup('teams', ref => 
			ref.orderBy('bookingRef').startAt(bookingRef).endAt(bookingRef+'\uf8ff')
			//ref.where("bookingRef", "==", bookingRef)
		)
		.snapshotChanges()
		.pipe(map(changes => {
			return changes.map(a => {
				//console.log('received mission:'+JSON.stringify(a.payload.doc.data()));
				let team = new Team();
				team.fromJSON(a.payload.doc.data());
				team.id = a.payload.doc.id;
				return team;
			});
		}));
	}

	isUniqueIdValid(id){
		return new Promise<boolean>(resolve => {
			this.afs.collectionGroup('teams', ref => ref.where('password', '==', id.toUpperCase()).where('complete', '==', false))
			.get().pipe(take(1))
			.subscribe(teams => {
				if(teams && teams.docs.length>0){
					resolve(false);
				}
				resolve(true);
			});
		});
	}
	
	addTeam(gameId, team) {
		return this.afs.doc(`games/${gameId}`).collection('teams').add({ ...team });
	}

	setTeam(gameId, teamUid, data){
		return this.afs.doc(`games/${gameId}`).collection('teams').doc(`${teamUid}`).set(data);
	}

	updateTeam(gameId, teamUid, data){
		return this.afs.doc(`games/${gameId}`).collection('teams').doc(`${teamUid}`).update(data);
	}

	deleteTeam(gameId, teamUid) {
		return this.afs.doc(`games/${gameId}`).collection('teams').doc(`${teamUid}`).delete();
	}

	async resetTeam(gameId, teamUid, groupUid) {
		this.afs.doc(`games/${gameId}`).collection('teams').doc(`${teamUid}`).collection('missions').get()
		.pipe(take(1))
		.subscribe(async missions => {
			var batch = this.afs.firestore.batch();

			missions.forEach((mission: any) => {
				batch.delete(mission.ref);
			});

			// Commit the batch
			await batch.commit();

			/*missions.forEach(async a => {
				console.log('Delete mission '+a.id);
				await this.deleteTeamMission(gameId, teamUid, a.id);
			});*/
		});

		this.afs.doc(`games/${gameId}`).collection('teams').doc(`${teamUid}`).collection('objects').get()
		.pipe(take(1))
		.subscribe(async objects => {
			var batch = this.afs.firestore.batch();

			objects.forEach((object: any) => {
				if(!object.ticketId){
					batch.delete(object.ref);
				}
			});

			// Commit the batch
			await batch.commit();
			/*object.forEach(async a => {
				console.log('Delete object '+a.id);
				await this.deleteTeamObject(gameId, teamUid, a.id);
			});*/
		});

		this.afs.doc(`games/${gameId}`).collection('teams').doc(`${teamUid}`).collection('events').get()
		.pipe(take(1))
		.subscribe(async events => {
			var batch = this.afs.firestore.batch();

			events.forEach((event: any) => {
				batch.delete(event.ref);
			});

			// Commit the batch
			await batch.commit();
			/*events.forEach(async a => {
				console.log('Delete event '+a.id);
				await this.deleteTeamEvent(gameId, teamUid, a.id);
			});*/
		});

		this.afs.doc(`games/${gameId}`).collection('teams').doc(`${teamUid}`).collection('players').get()
		.pipe(take(1))
		.subscribe(async players => {
			var batch = this.afs.firestore.batch();

			players.forEach((event: any) => {
				batch.update(event.ref, {
					roleId: firebase.firestore.FieldValue.delete(),
					startTime: firebase.firestore.FieldValue.delete()
				});
			});

			// Commit the batch
			await batch.commit();
			/*events.forEach(async a => {
				console.log('Delete event '+a.id);
				await this.deleteTeamEvent(gameId, teamUid, a.id);
			});*/
		});

		if(groupUid){
			this.afs.doc(`games/${gameId}`).collection(`groups/${groupUid}/mission_scores`, ref => ref.where('teamId', '==', teamUid))
			.get()
			.pipe(take(1))
			.subscribe(async scores => {
				if(scores.docs.length>0){
					var batch = this.afs.firestore.batch();
		
					scores.forEach((score: any) => {
						batch.delete(score.ref);
					});
		
					// Commit the batch
					await batch.commit();
				}
			});
		}

		await this.updateTeam(gameId, teamUid, 
			{
				score:0, 
				progress:0, 
				completedSteps:0, 
				timeBonus:0, 
				open:true, 
				complete:false, 
				paused:false, 
				resuming:false, 
				failed:firebase.firestore.FieldValue.delete(), 
				initiated:firebase.firestore.FieldValue.delete(), 
				deadline:firebase.firestore.FieldValue.delete(), 
				startTime:firebase.firestore.FieldValue.delete(), 
				stopTime:firebase.firestore.FieldValue.delete(), 
				duration:firebase.firestore.FieldValue.delete(), 
				pauseDuration:firebase.firestore.FieldValue.delete(), 
				currentMissionId:firebase.firestore.FieldValue.delete(), 
				leaderPlayerId:firebase.firestore.FieldValue.delete(), 
				timerStopped:firebase.firestore.FieldValue.delete(), 
				fallbackMissionId:firebase.firestore.FieldValue.delete(), 
				finalMissionReached:firebase.firestore.FieldValue.delete(), 
				gameOverUrl:firebase.firestore.FieldValue.delete()
			});
	}

	deleteTeamPicture(gameId,teamId,eventId){
		return this.afs.collection(`picture_deletion_requests`).add({
            time: new Date().getTime(),
            gameId: gameId,
            teamId: teamId,
            eventId: eventId
        })
	}

	updateTeamMission(gameId, teamUid, missionId, data){
		return this.afs.doc(`games/${gameId}/teams/${teamUid}`).collection('missions').doc(`${missionId}`).update(data);
	}

	setTeamMission(gameId, teamUid, missionId, data){
		return this.afs.doc(`games/${gameId}/teams/${teamUid}`).collection('missions').doc(`${missionId}`).set(data);
	}

	async resetTeamMission(gameId, teamUid, missionId) {
		this.afs.doc(`games/${gameId}`).collection('missions').get()
		.pipe(take(1))
		.subscribe(missions => {
			missions.forEach(async a => {
				if(a.id == missionId){
					console.log('Delete mission '+a.id);
					await this.deleteTeamMission(gameId, teamUid, a.id);
					let data = a.data();
					data.scope = 'team';
					await this.afs.doc(`games/${gameId}/teams/${teamUid}`).collection('missions').doc(`${missionId}`).set(a.data());
				}
			});
		});
	}

	deleteTeamMission(gameId, teamUid, missionId){
		return this.afs.doc(`games/${gameId}/teams/${teamUid}`).collection('missions').doc(`${missionId}`).delete();
	}

	addTeamObject(gameId, teamId, object) {
		return this.afs.doc(`games/${gameId}/teams/${teamId}`).collection('objects').add({ ...object });
	}

	deleteTeamObject(gameId, teamUid, objectUid) {
		return this.afs.doc(`games/${gameId}/teams/${teamUid}`).collection('objects').doc(`${objectUid}`).delete();
	}

	deleteTeamEvent(gameId, teamUid, eventId){
		return this.afs.doc(`games/${gameId}/teams/${teamUid}`).collection('events').doc(`${eventId}`).delete();
	}

	listTeamPlayers(gameId, teamId){
		return this.afs.doc(`games/${gameId}/teams/${teamId}`).collection('players')
		.valueChanges({ idField: 'id' })
		.pipe(map(collection => {
			return collection.map(data => {
				let object = new Player();
				object.fromJSON(data);
				return object;
			});
		}));
	}

	listTeamMissions(gameId, teamId){
		return this.afs.doc(`games/${gameId}/teams/${teamId}`).collection('missions', ref => ref.orderBy('orderNum','asc'))
		.valueChanges({ idField: 'id' })
		.pipe(map(collection => {
			return collection.map(data => {
				let object = new Mission(`games/${gameId}/teams/${teamId}/missions`);
				object.fromJSON(data);
				return object;
			});
		}));
	}

	listTeamEvents(gameId, teamId){
		return this.afs.doc(`games/${gameId}/teams/${teamId}`).collection('events')
		.valueChanges({ idField: 'id' })
		.pipe(map(collection => {
			return collection.map(data => {
				let object = new Event();
				object.fromJSON(data);
				return object;
			});
		}));
	}

	listTeamMailings(gameId, teamId){
		return this.afs.doc(`games/${gameId}/teams/${teamId}`).collection('mailings', ref => ref.orderBy('time','desc'))
		.valueChanges({ idField: 'id' });
	}

	createMailing(gameUid, teamId, mailing){
		return this.afs.doc(`games/${gameUid}/teams/${teamId}`).collection('mailings').add(mailing);
	}
}
